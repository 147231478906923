import { initCurexChatWidget } from "@curex/chat-widget";
import { addCookieConsentBanner, authentication, getUser, redirect, updateAnalyticsWithUserData } from "./helpers";
import { RedditService } from "~/services/reddit";
import { loadRedditPixel } from "~/utils/analytics/loadRedditPixel";
import * as utils from "~/utils";

import { UserModel } from "~/services/ApiUser/models";
import { TikTokService } from "~/services/tiktok";
import { QueryStorageService } from "~/services/QueryStorage/QueryStorage";
import { updateBackendUtmInformation } from "~/services/QueryStorage/api";
import { loadBrandzooka, loadClarity, loadTikTokPixel, pageViewBrandzooka } from "~/utils/analytics";
import { saveCoupon } from "~/services/storage/helpers/saveCoupon";
import { saveReferral } from "~/services/storage/helpers/saveReferral";
import { StorageService } from "~/services/storage/Storage";
import { AnalyticsService } from "~/services/analytics/Analytics";
import {
  getAppContext,
  getCampaignFromQueryStorage,
  getDeviceContext,
  getPageContext,
  getTraitsFromUserData,
} from "~/services/analytics/helpers";

export { StorageService as storageService } from "~/services/storage/Storage";
export { QueryStorageService as queryStorageService } from "~/services/QueryStorage/QueryStorage";
export { ApiUserService as apiUserService } from "~/services/ApiUser";
export { ApiAuthorizationService as apiAuthorizationService } from "~/services/ApiAuthorization";

const user: { information: UserModel; isAuthenticated: boolean } = {
  information: null,
  isAuthenticated: false,
};

const init = async (): Promise<void> => {
  saveCoupon(StorageService);
  saveReferral(StorageService);
  QueryStorageService.addVisitModel();

  loadBrandzooka(pageViewBrandzooka);
  loadClarity();
  loadTikTokPixel(process.env.CX_TIKTOK_PIXEL_ID || "CC1RQERC77U9MSBJI870", () => TikTokService.executeTasks());
  loadRedditPixel(process.env.CX_REDDIT_PIXEL_ID, () => RedditService.init());

  AnalyticsService.updateContext({
    app: getAppContext(),
    device: getDeviceContext(),
    campaign: getCampaignFromQueryStorage(QueryStorageService),
    channel: "browser",
  });

  AnalyticsService.initDestinations();

  const emitPageViews = () => {
    pageViewBrandzooka();

    TikTokService.page();
    RedditService.page();
    AnalyticsService.updatePage(getPageContext());
    AnalyticsService.page();
  };

  await authentication(async () => {
    const newUser: UserModel | void = await getUser();
    if (!newUser) {
      return;
    }

    user.information = newUser;
    user.isAuthenticated = true;

    AnalyticsService.updateTraits(getTraitsFromUserData(user.information));
    updateAnalyticsWithUserData(user.information);
    await updateBackendUtmInformation();
  });

  emitPageViews();
  redirect();

  if (!window.location.pathname.includes("/weightloss")) {
    try {
      window?.localStorage?.removeItem?.("kai");
      await initCurexChatWidget(process.env.CX_CUREX_CHAT_WIDGET_MOUNT_POINT || "#vueApp", {
        useChatUerStore: { user },
      });
    } catch (er) {
      console.error(er);
    }
  }

  addCookieConsentBanner();
};

export * from "./fetch";

export { AnalyticsService as analyticsService };
export { TikTokService as tikTokService };
export { RedditService as redditService };
export { init };
export { updateAnalyticsWithUserData };
export { updateBackendUtmInformation };
export { user };

export { utils };
